import * as types from '../constants/actions';
import fetch from 'isomorphic-fetch';
import async from 'async';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';

export function getInitialData(history) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch({type: types.LOCK_RESET});
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getQuestions = function(data, cbQuestions) {
      fetch(`${_uri}/api/service/${getState().booking.bookingSelected.ServiceId}/questions`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbQuestions(new Error(data.message));
            })
          } else {
            cbQuestions(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(questions => {
            dispatch({type: types.LOCK_SET_QUESTIONS, values: questions});
            cbQuestions(null,null);
          })
        }
      })
      .catch(err => {
        cbQuestions(err);
      });
    };

    const getRules = function(data, cbRules) {
      fetch(`${_uri}/api/service/${getState().booking.bookingSelected.ServiceId}/lockrules`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbRules(new Error(data.message));
            })
          } else {
            cbRules(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(rules => {
            dispatch({type: types.LOCK_SET_RULES, values: rules});
            cbRules(null,null);
          })
        }
      })
      .catch(err => {
        cbRules(err);
      });
    };

    async.waterfall([
      start,
      getQuestions,
      getRules
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        }
    });
  }
}

export function updateQuestion(id, checked) {
  return { type: types.LOCK_UPDATE_QUESTION, value: {id, checked} };
}

export function validateLocks(history, nextRoute) {
  return function (dispatch, getState) {
    let _break = false;
    getState().lock.rules.map((r) => {
      switch (r.lockRuleEntity) {
        case 'Pet':
          console.log(JSON.parse(r.lockRuleValues));
          console.log(getState().pet.petSelected[r.lockRuleField]);
          if (JSON.parse(r.lockRuleValues).includes(getState().pet.petSelected[r.lockRuleField])) {
            dispatch(MessageActions.show(`Lamentablemente no podemos reservar turnos para ${r.lockRuleLabel} de tu mascota`));
            _break = true;
          }
          break;
        default:
      }
      return null;
    });
    if (_break) {
      return null;
    } else {
      let _questionsCounter = 0;
      getState().lock.questions.map((q) => {
        if (q.checked) _questionsCounter ++;
        return null;
      });
      if (_questionsCounter === getState().lock.questions.length) {
        history.push(nextRoute);
      } else {
        dispatch(MessageActions.show('No podemos realizar la reserva del turno si no se cumplen todos los criterios'));
      }
    }
  }
}
