import { CONTAINER_TOGGLE_DRAWER, CONTAINER_SET_CRITERIA } from '../constants/actions';

const _state={
  drawer: false,
  criteria: '',
}

export default function login(state = _state, action) {
  switch (action.type) {
  case CONTAINER_TOGGLE_DRAWER:
    return {...state, drawer: !state.drawer};
  case CONTAINER_SET_CRITERIA:
    return {...state, criteria: action.value};
  default:
    return state;
  }
}
