import { connect } from "react-redux";
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Confirmation extends React.Component {
  logout() {
    this.props.login.logout();
    this.props.history.push('/');
  }

  render() {
    return (
      <div className='home'>
        <div className='card'>
          <div className='homeTitle' style={this.props.bookingResult.ok?{color:'black'}:{color:'firebrick'}}>{this.props.bookingResult.ok?'Felicitaciones!':'Ha ocurrido un error!'}</div>
          {
            this.props.bookingResult.ok ?
              <div className='homeSubtitle' style={{marginTop: 16}}>
                {`Reservaste un turno de ${this.props.bookingSelected.serviceName} en ${this.props.bookingSelected.locationName} para ${this.props.petSelected.petName} el día ${moment.unix(this.props.bookingSelected.bookingStartDate).utc().format('DD/MM/YYYY')} a las ${moment.unix(this.props.bookingSelected.bookingStartDate).utc().format('HH:mm')}`}
              </div>
            :
              <div className='homeSubtitle'>
                {this.props.bookingResult.message}
              </div>
          }
          <Grid container style={{marginTop: 24}}>
            <Grid item xs={12} style={{marginTop: 16, marginBottom: 0}}>
              <Button color='secondary' onClick={() => this.props.history.push('/')}>Volver al inicio</Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookingSelected: state.booking.bookingSelected,
    bookingResult: state.booking.bookingSaveResult,
    petSelected: state.pet.petSelected
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Confirmation));
