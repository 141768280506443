import * as types from '../constants/actions';
import fetch from 'isomorphic-fetch';
import async from 'async';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import * as BookingActions from '../actions/booking';

export function getInitialData(history) {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch({type: types.TERMS_RESET});
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getTerms = function(data, cbTerms) {
      fetch(`${_uri}/open/terms`,
        {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbTerms(new Error(data.message));
            })
          } else {
            cbTerms(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(terms => {
            dispatch({type: types.TERMS_SET_LIST, values: terms});
            cbTerms(null,null);
          })
        }
      })
      .catch(err => {
        cbTerms(err);
      });
    };

    async.waterfall([
      start,
      getTerms
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        }
    });
  }
}

export function updateTerms(id, checked) {
  return { type: types.TERMS_UPDATE_TERM_ACCEPTANCE, value: {id, checked} };
}

export function validateTerms(history, nextRoute) {
  let _uri = config.server;

  return function (dispatch, getState) {
    let _termsCounter = 0;
    getState().terms.terms.map((t) => {
      if (t.checked) _termsCounter ++;
      return null;
    });

    if (_termsCounter === getState().terms.terms.length) {

      const start = function(cb) {
        dispatch(OverlayActions.show());
        cb(null,null);
      };

      const end = function() {
        dispatch(OverlayActions.hide());
      };

      const updateTerms = function(data, cbTerms) {
        fetch(`${_uri}/api/user/${getState().login.userId}/termsacceptance`,
          {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          }
        )
        .then(function(response) {
          if (!response.ok) {
            if (response.status < 500) {
              response.json()
              .then(data => {
                cbTerms(new Error(data.message));
              })
            } else {
              cbTerms(new Error(response.statusText));
            }
          } else {
            response.json()
            .then(terms => {
              cbTerms(null,null);
            });
          }
        })
        .catch(err => {
          cbTerms(err);
        });
      };

      async.waterfall([
        start,
        updateTerms
      ], function (error,result) {
          end();
          if (error) {
            console.log(error);
            dispatch(MessageActions.show(error.message));
          } else {
            dispatch(BookingActions.createBooking(history, nextRoute));
          }
      });
    } else {
      dispatch(MessageActions.show('Para utilizar el servicio tenes que aceptar los términos y condiciones'));
    }
  }
}
