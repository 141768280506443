import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import * as PetsEditorActions from '../actions/petsEditor';
import _ from "underscore";

class PetEditor extends Component {
  componentDidMount() {
    this.props.actions.petsEditor.getInitialData();
  }

  render() {
    let readOnly = false;//(this.props.roleName === 'USER');
    let _title= (this.props.id > 0 ? (readOnly ? `Información de ${this.props.petName}` : 'Edición de mascota') : ' Alta de mascota');
    const _sortedSpecies = _.sortBy(this.props.Species, x => x.specieName);
    const _filteredBreeds = _.sortBy(this.props.Breeds.filter(x => x.SpecieId === this.props.SpecieId), x => x.breedName);
    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container style={{paddingLeft: 16, paddingRight: 16, marginBottom: 48}}>
          <TextField
            fullWidth
            required
            autoFocus
            margin="normal"
            InputLabelProps={{ shrink: true, }}
            label='Nombre'
            value={this.props.petName}
            onChange={(e) => this.props.actions.petsEditor.updateField({attr:'petName', value:e.target.value})}
            disabled={readOnly}
          />
          <FormControl
            fullWidth
            required
            autoFocus
            style={{marginTop: 16}}
            disabled={readOnly}
          >
            <InputLabel shrink>Especie</InputLabel>
            <Select
              value={this.props.SpecieId}
              onChange={(event) => this.props.actions.petsEditor.setSelectedSpecie(event.target.value)}
            >
              <MenuItem key={`petsEditorSpecie_0`} value={0}>{'Ninguna'}</MenuItem>
              {
                _sortedSpecies && _sortedSpecies.map((_specie) => {
                  return (<MenuItem key={`petsEditorSpecie_${_specie.id}`} value={_specie.id}>{_specie.specieName}</MenuItem>)
                })
              }
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            required
            autoFocus
            style={{marginTop: 16}}
            disabled={readOnly}
          >
            <InputLabel shrink>Raza</InputLabel>
            <Select
              value={this.props.BreedId}
              onChange={(event) => this.props.actions.petsEditor.setSelectedBreed(event.target.value)}
            >
              <MenuItem key={`petsEditorBreed_0`} value={0}>{'Ninguna'}</MenuItem>
              {
                _filteredBreeds.map((_breed) => {
                  return (<MenuItem key={`petsEditorBreed_${_breed.id}`} value={_breed.id}>{_breed.breedName}</MenuItem>)
                })
              }
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            required
            autoFocus
            style={{marginTop: 16}}
            disabled={readOnly}
          >
            <InputLabel shrink>Género</InputLabel>
            <Select
              value={this.props.petGender}
              onChange={(event) => this.props.actions.petsEditor.updateField({attr:'petGender', value:event.target.value})}
            >
              <MenuItem key={`petsEditorGender_X`} value={'X'}>{'Ninguno'}</MenuItem>
              <MenuItem key={`petsEditorGender_F`} value={'F'}>{'Hembra'}</MenuItem>
              <MenuItem key={`petsEditorGender_M`} value={'M'}>{'Macho'}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            required
            autoFocus
            margin="normal"
            InputLabelProps={{ shrink: true, }}
            label="Fecha de nacimiento"
            type="date"
            value={this.props.petBirthDate ? moment(this.props.petBirthDate).format('YYYY-MM-DD') : null}
            onChange={(e) => this.props.actions.petsEditor.updateField({attr:'petBirthDate', value:e.target.value})}
            disabled={readOnly}
          />
        </Grid>
        <Fab key='petClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
        {
          !readOnly ?
            <Fab key='petSave' size="small" color="secondary" aria-label="Save" style={{position: 'fixed', bottom: '60px', right: '20px'}} onClick={() => this.props.actions.petsEditor.savePet(this.props.history)}>
              <SaveIcon />
            </Fab>
          : null
        }
        {
          this.props.id > 0 && !readOnly ?
            <Fab key='petDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.petsEditor.deletePet(this.props.history)}>
              <DeleteIcon />
            </Fab>
          : null
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    id: state.petsEditor.pet.id,
    petName: state.petsEditor.pet.petName,
    petGender: state.petsEditor.pet.petGender,
    petBirthDate: state.petsEditor.pet.petBirthDate,
    SpecieId: state.petsEditor.pet.Breed.Specie.id,
    BreedId: state.petsEditor.pet.Breed.id,
    Species: state.petsEditor.Species,
    Breeds: state.petsEditor.Breeds,
    roleName: state.login.roleName,
    userId:state.login.userId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      petsEditor: bindActionCreators(PetsEditorActions, dispatch),
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(PetEditor);
