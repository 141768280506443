import * as types from '../constants/actions';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import moment from 'moment';
import async from 'async';

export function setDate(value) {
  const _unixDate = moment.utc(moment(value).format('YYYY-MM-DD 00:00:00')).unix();
  return { type: types.BOOKING_SET_DATE, value: _unixDate };
}

export function getDataByDate(value) {
  return (dispatch, getState) => {
    dispatch(setDate(value));
    dispatch(getInitialData());
  };
}

export function validateSelection(history, nextRoute) {
  return function (dispatch, getState) {
    if (getState().booking.bookingSelected) {
      history.push(nextRoute);
    } else {
      dispatch(MessageActions.show('Tenes que seleccionar un turno para continuar'));
    }
  }
}

export function setTimeRange(start, end) {
  return { type: types.BOOKING_SET_DATE, start, end };
}

export function setAvailableBookingsList(availableBookings) {
  return { type: types.BOOKING_SET_AVAILABLES_LIST, values: availableBookings };
}

export function selectBooking(item) {
  return { type: types.BOOKING_SET_SELECTED, value: item };
}

export function getInitialData() {
  let _uri = config.server;

  return function (dispatch, getState) {
    let _results={};

    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getAvailableBookings = function(data, cb) {
      fetch(`${_uri}/open/bookings/availables/${getState().booking.ServiceId}/${getState().booking.LocationId}/${getState().booking.OperatorUserId}/${getState().booking.bookingDate}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              _results.availableBookings=data;
              cb(null,data);
            })
          } else {
            _results.availableBookings=[];
            cb(null,[]);
          }
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getAvailableBookings
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch(setAvailableBookingsList(_results.availableBookings));
        }
    });
  }
}

export function createBooking(history, nextRoute = '') {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const saveBooking = function(data, cbSave) {
      const _booking = getState().booking.bookingSelected;
      const _userId = getState().login.userId;
      const _pet = getState().pet.petSelected;

      fetch(`${_uri}/api/booking`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            bookingStartDate: _booking.bookingStartDate,
            bookingEndDate: _booking.bookingEndDate,
            bookingComment: '',
            bookingStatus: 'A',
            ServiceId: _booking.ServiceId,
            BookerUserId: _userId,
            OperatorUserId: _booking.OperatorUserId,
            LocationId: _booking.LocationId,
            PetId: _pet.id
          })
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cbSave(new Error(data.message));
            })
          } else {
            cbSave(new Error(response.statusText));
          }
        } else {
          if (response.status === 200) {
            response.json()
            .then(data => {
              cbSave(null,data);
            })
          } else {
            cbSave(null,null);
          }
        }
      })
      .catch(err => {
        cbSave(err);
      });
    };

    async.waterfall([
      start,
      saveBooking
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          if (nextRoute !== '') {
            dispatch({type: types.BOOKING_SET_SAVE_RESULT, value: {ok: 0, message: error.message, result: null}});
            history.push(nextRoute);
          } else {
            dispatch(MessageActions.show(error.message));
          }
        } else {
          if (nextRoute !== '') {
            dispatch({type: types.BOOKING_SET_SAVE_RESULT, value: {ok: 1, message: 'Felicitaciones! Se registró el turno solicitado', result}});
            history.push(nextRoute);
          } else {
            dispatch(MessageActions.show('Felicitaciones! Se registró el turno solicitado'));
          }
        }
    });
  }
}
