import { REPORT_SET_DATA } from '../constants/actions';

export default function report(state = {data:[]}, action) {
  switch (action.type) {
    case REPORT_SET_DATA:
      return {...state, data: action.value};
    default:
      return state;
  }
}
