import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PetActions from '../actions/pet';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import BookingContainer from './bookingContainer';
import ReactCardFlip from 'react-card-flip';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker } from "@material-ui/pickers";
import _ from "underscore";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class SignInUp extends React.Component {
  componentDidMount() {
    this.props.actions.pet.getInitialData();
  }

  render() {
    const _sortedSpecies = _.sortBy(this.props.Species, x => x.specieName);
    const _filteredBreeds = _.sortBy(this.props.Breeds.filter(x => x.SpecieId === this.props.pet.SpecieId), x => x.breedName);

    return (
      <BookingContainer id="pets" activeStep={2} history={this.props.history} action={this.props.actions.pet.validatePet} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Grid container spacing={0} style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{height: 580, width: 400 }}>
              <ReactCardFlip isFlipped={this.props.isPetListFlipped} flipDirection="vertical">
                <div style={{height: 540, width: 360, padding: 20, textAlign: 'center', fontSize: 32, color: '#577600', borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderWidth: 1, borderColor: '#D0F271', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D0F271', cursor: 'pointer'}} onClick={() => this.props.actions.pet.setflipped('petsList', true)}>
                  Si ya tenés mascotas registradas y querés seleccionarlas de tu lista, hacé click aquí
                </div>
                <div style={{height: 580, width: 400, borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto'}}>
                  {
                    this.props.isPetListFlipped ?
                      <div className='bookingList' style={{width:300}}>
                        {
                          this.props.pets.length === 0 ?
                            <div className='bookingListItem'>
                              <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', textAlign: 'center'}}>No hay mascotas registradas</div>
                            </div>
                          :
                            this.props.pets.map((item, index) => {
                              return (
                                <div key={`petsListPet_${index}`} className={this.props.petSelected && item.id === this.props.petSelected.id ? 'selectedBookingListItem' : 'bookingListItem'} onClick={() => this.props.actions.pet.selectPet(item)}>
                                  <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>{item.petName}</div>
                                </div>
                              );
                            })
                        }
                      </div>
                    : null
                  }
                  </div>
              </ReactCardFlip>
          </div>
          <div style={{height: 580, width: 400}}>
              <ReactCardFlip isFlipped={this.props.isNewPetFlipped} flipDirection="vertical">
                <div style={{height: 540, width: 360, padding: 20, textAlign: 'center', fontSize: 32, color: '#122f55', borderTopRightRadius: 20, borderBottomRightRadius: 20, borderWidth: 1, borderColor: '#456389', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#456389', cursor: 'pointer'}} onClick={() => this.props.actions.pet.setflipped('newPet', true)}>
                  Si querés agregar una mascota hacé click aquí
                </div>
                <div style={{height: 580, width: 400, borderTopRightRadius: 20, borderBottomRightRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto'}}>
                  {
                    this.props.isNewPetFlipped ?
                    <Grid container style={{paddingLeft: 24, paddingRight: 24}}>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          margin='normal'
                          InputLabelProps={{ shrink: true }}
                          label='Nombre'
                          fullWidth={true}
                          required
                          value={this.props.pet.petName}
                          onChange={(event) => this.props.actions.pet.setField('petName', event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                          autoFocus
                          style={{marginTop: 16, marginBottom: 8}}
                        >
                          <InputLabel shrink style={{backgroundColor: 'white', paddingLeft: 2, paddingRight: 2}}>Especie</InputLabel>
                          <Select
                            value={this.props.pet.SpecieId}
                            onChange={(event) => this.props.actions.pet.setSpecieAndGetBreeds(event.target.value)}
                          >
                            {
                              _sortedSpecies && _sortedSpecies.map((_specie) => {
                                return (<MenuItem key={`petEditorSpecie_${_specie.id}`} value={_specie.id}>{_specie.specieName}</MenuItem>)
                              })
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          variant='outlined'
                          required
                          autoFocus
                          style={{marginTop: 16, marginBottom: 8}}
                        >
                          <InputLabel shrink style={{backgroundColor: 'white', paddingLeft: 2, paddingRight: 2}}>Raza</InputLabel>
                          <Select
                            value={this.props.pet.BreedId}
                            onChange={(event) => this.props.actions.pet.setField('BreedId', event.target.value)}
                          >
                            {
                              _filteredBreeds.map((_breed) => {
                                return (<MenuItem key={`petEditorBreed_${_breed.id}`} value={_breed.id}>{_breed.breedName}</MenuItem>)
                              })
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          variant='outlined'
                          required
                          autoFocus
                          style={{marginTop: 16, marginBottom: 8}}
                        >
                          <InputLabel shrink style={{backgroundColor: 'white', paddingLeft: 2, paddingRight: 2}}>Género</InputLabel>
                          <Select
                            value={this.props.pet.petGender}
                            onChange={(event) => this.props.actions.pet.setField('petGender', event.target.value)}
                          >
                            <MenuItem key={`petEditorGender_F`} value={'F'}>Hembra</MenuItem>
                            <MenuItem key={`petEditorGender_M`} value={'M'}>Macho</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <KeyboardDatePicker
                          autoOk
                          required
                          style={{width:352, marginTop: 16, marginBottom: 8}}
                          inputVariant="outlined"
                          label="Fecha de nacimiento estimada"
                          format="DD/MM/YYYY"
                          maxDate={Date.now()}
                          cancelLabel="CANCELAR"
                          okLabel="ACEPTAR"
                          value={this.props.pet.petBirthDate}
                          InputAdornmentProps={{ position: "start" }}
                          onChange={date => this.props.actions.pet.setField('petBirthDate', date)}
                        />
                      </Grid>
                      <div className='row' style={{backgroundColor:'rgba(255,255,255,0.25)', paddingTop:16, width:'100%', position: 'relative'}}>
                        <Button variant="contained" fullWidth={true} color="secondary" onClick={() => this.props.actions.pet.savePet(this.props.history)}>Agregar</Button>
                      </div>
                    </Grid>
                    : null
                  }
                </div>
              </ReactCardFlip>
          </div>
        </Grid>
      </BookingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.user.error,
    pet: state.pet.pet,
    pets: state.pet.pets,
    isPetListFlipped: state.pet.petsListFlipped,
    isNewPetFlipped: state.pet.newPetFlipped,
    login: state.login,
    Species: state.pet.species,
    Breeds: state.pet.breeds,
    petSelected: state.pet.petSelected
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pet: bindActionCreators(PetActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignInUp));
