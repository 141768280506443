import { PETS_LIST_SET_HEIGHT, PETS_LIST_SET_LIST, PETS_LIST_SET_SELECTED_PET } from '../constants/actions';

export default function newsList(state = {height:0,pets:[],selectedPet:null}, action) {
  switch (action.type) {
    case PETS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case PETS_LIST_SET_LIST:
      return {...state, pets: action.value};
    case PETS_LIST_SET_SELECTED_PET:
      return {...state, selectedPet: action.value};
    default:
      return state;
  }
}
