import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import Container from './container';
import * as TermsAndConditionsActions from '../actions/termsAndConditions';
import * as ContainerActions from '../actions/container';
import TextField from "@material-ui/core/TextField";

class AdminReportsPage extends Component {

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    //this.props.actions.PetsList.updateHeight(height - 64);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.props.actions.TermsAndConditions.getInitialData();
    }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Términos y Condiciones</div>
          <div style={{paddingLeft: 8, paddingRight: 8}}>
            <TextField
              id="multiline-static"
              label="Declaración"
              fullWidth
              multiline
              rows="25"
              InputLabelProps={{ shrink: true }}
              value={this.props.termsAndConditionsDescription}
              placeholder="Ingrese los términos y condiciones"
              margin="normal"
              onChange={(e) => this.props.actions.TermsAndConditions.updateField({attr:'termsAndConditionsDescription', value:e.target.value})}
            />
          </div>
          <Fab key='save' size="small" color="secondary" aria-label="Refresh" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.TermsAndConditions.saveTermsAndConditions(this.props.history)}>
            <SaveIcon />
          </Fab>
        </div>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    termsAndConditionsDescription:state.termsAndConditions.termsAndConditionsDescription,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      TermsAndConditions: bindActionCreators(TermsAndConditionsActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportsPage);
