import { TERMSANDCONDITIONS_SET_DATA,TERMSANDCONDITIONS_EDITOR_UPDATE_FIELD } from '../constants/actions';

export default function report(state = {termsAndConditionsDescription:''}, action) {
  let _state= {...state};
  switch (action.type) {
    case TERMSANDCONDITIONS_EDITOR_UPDATE_FIELD:
      _state[action.value.attr] = action.value.value;
      return _state;

    case TERMSANDCONDITIONS_SET_DATA:
      return {...state, termsAndConditionsDescription: action.value.termsAndConditionsDescription};
    default:
      return state;
  }
}
