import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import * as LoginActions from '../actions/login';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Home extends React.Component {
  logout() {
    this.props.actions.login.logout();
    this.props.history.push('/');
  }

  render() {
    return (
      <div className='home'>
        <div className='card'>
          <div className='homeTitle'>¡Bienvenido a Zoonosis!</div>
          <div className='homeSubtitle'>Municipio de Malvinas Argentinas</div>
          <Grid container style={{marginTop: 24}}>
            <Grid item xs={12} sm={6} className='leftButton' >
              <Button className='homeButton' variant="contained" color='secondary' onClick={() => this.props.history.push('booking-selector')}>Reservar un turno</Button>
            </Grid>
            <Grid item xs={12} sm={6} className='rightButton' >
              <Button className='homeButton' variant="contained" color='primary' onClick={() => this.props.history.push('adminPets')}>Ver mis turnos</Button>
            </Grid>
            <Grid item xs={12} style={{marginTop: 16, marginBottom: 0}}>
              {
                this.props.login.token?
                  <Button color='secondary' onClick={() => this.logout()}>Cerrar sesión</Button>
                : <Button color='secondary' onClick={() => this.props.history.push('admin-pets')}>Ingresar con mi usuario</Button>
              }

            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(LoginActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home));
