import { USERS_LIST_SET_HEIGHT, USERS_LIST_SET_LIST, USERS_LIST_SET_SELECTED_USER } from '../constants/actions';

export default function newsList(state = {height:0,users:[],selectedUser:null}, action) {
  switch (action.type) {
    case USERS_LIST_SET_HEIGHT:
      return {...state, height: action.value};
    case USERS_LIST_SET_LIST:
      return {...state, users: action.value};
    case USERS_LIST_SET_SELECTED_USER:
      return {...state, selectedUser: action.value};
    default:
      return state;
  }
}
