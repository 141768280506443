import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import VirtualizedTable from './extendedVirtualizedTable';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import Container from './container';
import * as BookingsListActions from '../actions/bookingsList';
import moment from 'moment';
import _ from 'underscore';

class AdminBookings extends Component {

  rowConvert(rows, index, history) {
    const row = rows[index];
    let convertedRow = {};
    Object.keys(row).map((key) => {
      if (key === 'bookingDelete') {
        convertedRow[key] = { type: 'button', value: row[key], action: () => alert(row.data.id)};
      } else {
        convertedRow[key] = { type: 'text', value: row[key], action: (event) => this.props.actions.BookingsList.selectBooking(row.data, history) };
      }
      return null;
    });
    return convertedRow;
  }

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.actions.BookingsList.updateHeight(height - 64);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    this.props.actions.BookingsList.getInitialData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    let rows = this.props.bookings.map((_booking) => {
      return ({
        id: _booking.id,
        petName: _booking.Pet.petName,
        bookingStartDate: moment.unix(_booking.bookingStartDate).utc().format('DD/MM/YYYY HH:mm'),
        serviceName: _booking.Service.serviceName,
        userFullName: `${_booking.Booker.userName} ${_booking.Booker.userLastName}`,
        bookingDelete: 'Cancelar',
        data: _booking
      });
    });

    rows = _.sortBy(rows, x => x.bookingStartDate);

    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Turnos reservados</div>
          <VirtualizedTable
            rowCount={rows.length}
            rowGetter={({ index }) => this.rowConvert(rows, index, this.props.history)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Mascota', dataKey: 'petName' },
              { width: 120, flexGrow: 1.0, label: 'Fecha', dataKey: 'bookingStartDate' },
              { width: 120, flexGrow: 1.0, label: 'Servicio', dataKey: 'serviceName' },
              { width: 120, flexGrow: 1.0, label: 'Propietario', dataKey: 'userFullName' },
              { width: 80, flexGrow: 1.0, label: 'Cancelar', dataKey: 'bookingDelete' }
            ]}
          />
        </div>
        <Fab key='bookingsClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.bookingList.height,
    bookings: state.bookingList.bookings
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      BookingsList: bindActionCreators(BookingsListActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminBookings);
