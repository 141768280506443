import React from 'react';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';

const steps = [
  { id: 1, name: '', next: 'signInUp', title: 'SELECCIONA EL TURNO ENTRE LAS OPCIONES DISPONIBLES'},
  { id: 2, name: '', next: 'pets', title: 'REGISTRATE COMO USUARIO DEL SISTEMA'},
  { id: 3, name: '', next: 'locks', title: 'REGISTRA LA INFORMACIÓN DE TU MASCOTA'},
  { id: 4, name: '', next: 'terms', title: 'CONFIRMA LOS SIGUIENTES DATOS'},
  { id: 5, name: '', next: 'confirmation', title: 'TÉRMINOS Y CONDICIONES'}
];

class BookingContainer extends React.Component {

  goNext(route) {
    if (this.props.action) this.props.action(this.props.history, route);
    else this.props.history.push(route);
  }

  render() {
    return (
      <div style={{height: '100%'}}>
        <div>
          <div className='bookingTitle'>¡Bienvenido a Zoonosis!</div>
          <div className='bookingSubtitle'>SISTEMA DE RESERVA DE TURNOS</div>
          <div className='bookingStepperContainer'>
            <Stepper className='stepper' activeStep={this.props.activeStep} alternativeLabel>
              {
                steps.map((step) =>
                  <Step key={`step${step.id}`}>
                    <StepLabel>{step.name}</StepLabel>
                  </Step>
                )
              }
            </Stepper>
          </div>
          <div className='bookingStepTitle'>{steps[this.props.activeStep].title}</div>
          {this.props.children}
          <Grid container className='bookingContainerButton'>
            <Grid item xs={12} sm={6} className={'leftButton'} >
              <Button className='homeButton' variant="contained" color="primary" onClick={() => this.props.history.goBack()}>Anterior</Button>
            </Grid>
            <Grid item xs={12} sm={6} className='rightButton' >
              <Button className='homeButton' variant="contained" color="secondary" onClick={() => this.goNext(steps[this.props.activeStep].next)}>Siguiente</Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default BookingContainer;
