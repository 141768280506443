import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import PetsIcon from '@material-ui/icons/Pets';
import ExploreIcon from '@material-ui/icons/Explore';
import RoomIcon from '@material-ui/icons/Room';
import FaceIcon from '@material-ui/icons/Face';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from '../resources/img/logomma.png';
import * as LoginActions from '../actions/login';
import * as ContainerActions from '../actions/container';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    color:'#D0F271',
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing.unit*2,
      width: 'auto',
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logoutIcon:{
    color:'#D0F271',
  },
  menuItem:{
    color:'#456389',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '40px',
    height: 'auto',
    width: 'auto',
  },
});

class Container extends Component {

  redirectTo(value) {
    this.props.actions.container.redirectTo(this.props.history, value);
  }

  logout() {
    this.props.actions.login.logout();
    this.props.history.push('/');
  }

  toggleDrawer() {
    this.props.actions.container.toggleDrawer();
  }

  render() {
    const { classes } = this.props;
    const _isAdmin = this.props.roleName.toUpperCase()==="SYSADMIN";
    return (
      <div>
        <AppBar color='default' position="static" style={{width: '100%', padding: 0, margin: 0, position: 'fixed', left: 0, top: 0,background:'#272c45'}}>
          <Toolbar style={{width: '100%', padding: 0}}>
            <IconButton key='toolbarMenuButton' className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={() => this.toggleDrawer()}>
              <MenuIcon key='toolbarMenuButtonIcon' />
            </IconButton>
            <div style={{flexGrow: 1, padding: 0,cursor:'pointer'}}>
              <img alt='logo' className={classes.logo} src={logo} onClick={() => this.redirectTo('home')} />
            </div>
            <p style={{color:'#D0F271', textAlign: 'right'}}>{'Bienvenido ' +  this.props.userName}</p>
            <div>
              <IconButton aria-haspopup="true" onClick={() => this.logout()} className={classes.logoutIcon}>
                <LogoutIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer open={this.props.drawer} onClose={() => this.toggleDrawer()}>
          <div
            tabIndex={0}
            role="button"
            onClick={() => this.toggleDrawer()}
            onKeyDown={() => this.toggleDrawer()}
          >
            {_isAdmin?
              <ListItem button key='Usuarios'  onClick={() => this.redirectTo('admin-users')}>
                <ListItemIcon className={classes.menuItem}><PersonIcon /></ListItemIcon>
                <ListItemText classes={{primary:classes.menuItem}} primary='Usuarios' />
              </ListItem>
              :
              null
            }

            <ListItem button key='Mascotas' onClick={() => this.redirectTo('admin-pets')}>
              <ListItemIcon className={classes.menuItem}><PetsIcon /></ListItemIcon>
              <ListItemText classes={{primary:classes.menuItem}} primary='Mascotas' />
            </ListItem>

            {_isAdmin?
              <ListItem button key='admin-states' onClick={() => this.redirectTo('admin-states')}>
                <ListItemIcon className={classes.menuItem}><ExploreIcon /></ListItemIcon>
                <ListItemText classes={{primary:classes.menuItem}} primary='Provincias' />
              </ListItem>
              :
              null
            }
            {_isAdmin?
              <ListItem button key='Localidades' onClick={() => this.redirectTo('admin-cities')}>
                <ListItemIcon className={classes.menuItem}><RoomIcon /></ListItemIcon>
                <ListItemText classes={{primary:classes.menuItem}} primary='Localidades' />
              </ListItem>
              :
              null
            }
            {_isAdmin?
              <ListItem button key='Especies' onClick={() => this.redirectTo('admin-species')}>
                <ListItemIcon className={classes.menuItem}><FaceIcon /></ListItemIcon>
                <ListItemText classes={{primary:classes.menuItem}} primary='Especies' />
              </ListItem>
              :
              null
            }
            {_isAdmin?
              <ListItem button key='Razas' onClick={() => this.redirectTo('admin-breeds')}>
                <ListItemIcon className={classes.menuItem}><FingerprintIcon /></ListItemIcon>
                <ListItemText classes={{primary:classes.menuItem}} primary='Razas' />
              </ListItem>
              :
              null
            }
            {_isAdmin?
              <ListItem button key='Reportes' onClick={() => this.redirectTo('admin-reports')}>
                <ListItemIcon className={classes.menuItem}><AssessmentIcon /></ListItemIcon>
                <ListItemText classes={{primary:classes.menuItem}} primary='Reportes' />
              </ListItem>
              :
              null
            }
            {!_isAdmin?
              <ListItem button key='Turnos' onClick={() => this.redirectTo('admin-pets')}>
                <ListItemIcon className={classes.menuItem}><PetsIcon /></ListItemIcon>
                <ListItemText classes={{primary:classes.menuItem}} primary='Turnos' />
              </ListItem>
              :
              null
            }

            {_isAdmin?
              <ListItem button key='TermsAndConditions' onClick={() => this.redirectTo('admin-termsAndConditions')}>
                <ListItemIcon className={classes.menuItem}><PetsIcon /></ListItemIcon>
                <ListItemText classes={{primary:classes.menuItem}} primary='Términos y Condiciones' />
              </ListItem>
              :
              null
            }
          </div>
        </Drawer>
        <div style={{marginTop: '56px'}}>
          {this.props.children}
        </div>
        <div style={{textAlign:'center',height: '30px',width:'100%',position:'fixed',bottom:'0px',background:'#272c45',color:'#D0F271',borderTop:'4px solid #dddddd', paddingTop: 8}}>
          Version 1.0.0
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.container.selectedTab,
    criteria: state.container.criteria,
    drawer: state.container.drawer,
    roleName: state.login.roleName,
    userName: state.login.userName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(LoginActions, dispatch),
      container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Container));
