import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from '../actions/user';
import * as LoginActions from '../actions/login';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import BookingContainer from './bookingContainer';
import ReactCardFlip from 'react-card-flip';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import _ from "underscore";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class SignInUp extends React.Component {
  setEmail(e) {
    this.props.actions.login.setEmail(e.target.value);
  }

  setPwd(e) {
    this.props.actions.login.setPwd(e.target.value);
  }

  componentDidMount() {
    this.props.actions.user.getInitialData();
  }

  render() {
    const _sortedStates = _.sortBy(this.props.States, x => x.stateName);
    const _filteredCities = _.sortBy(this.props.Cities.filter(x => x.StateId === this.props.user.StateId), x => x.cityName);

    return (
      <BookingContainer id="signInUp" activeStep={1} history={this.props.history} action={this.props.actions.login.validateLogin} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {this.props.login.token ?
          <Grid container spacing={0} style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{height: 500, width: 719, borderRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto', fontSize: 32, flexDirection: 'column', padding: 40, color:'#456389', textAlign: 'center'}}>
              <p>Hola {this.props.login.userName}!</p>
              <p>Si querés continuar con esta cuenta presioná el botón "siguiente"</p>
              <p onClick={() => this.props.actions.login.logout()}>Sino hacé click <b style={{color: '#577600', cursor: 'pointer'}}>aquí</b> para cerrar sesión e ingresar o registrar una cuenta nueva</p>
            </div>
          </Grid>
          :
          <Grid container spacing={0} style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{height: 580, width: 400 }}>
                <ReactCardFlip isFlipped={this.props.isSignInFlipped} flipDirection="vertical">
                  <div style={{height: 540, width: 360, padding: 20, textAlign: 'center', fontSize: 32, color: '#577600', borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderWidth: 1, borderColor: '#D0F271', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D0F271', cursor: 'pointer'}} onClick={() => this.props.actions.user.setflipped('signIn', true)}>
                    Si ya estás registrado en el sistema hacé click aquí para ingresar
                  </div>
                  <div style={{height: 580, width: 400, borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto'}}>
                    {
                      this.props.isSignInFlipped ?
                      <div style={{width: 300}}>
                        <div className='row' style={{backgroundColor:'rgba(255,255,255,0.25)', width:'100%', position: 'relative'}}>
                          <TextField
                            variant='outlined'
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            label='Email'
                            fullWidth={true}
                            value={this.props.login.email}
                            onChange={(e) => this.setEmail(e)}
                          />
                        </div>
                        <div className='row' style={{backgroundColor:'rgba(255,255,255,0.25)', width:'100%', position: 'relative'}}>
                          <TextField
                            variant='outlined'
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            label='Password'
                            type='password'
                            fullWidth={true}
                            value={this.props.login.pwd}
                            onChange={(e) => this.setPwd(e)}
                            onKeyPress={(ev) => {
                              if (ev.key === 'Enter') {
                                ev.preventDefault();
                                this.props.actions.login.loginGetData(this.props.history);
                              }
                            }}
                          />
                        </div>
                        <div className='row' style={{backgroundColor:'rgba(255,255,255,0.25)', paddingTop:16, width:'100%', position: 'relative'}}>
                          <Button variant="contained" fullWidth={true} color="primary" onClick={() => this.props.actions.login.loginGetData(this.props.history, this.props.location && this.props.location.state ? this.props.location.state.originalPath : '')}>Entrar</Button>
                        </div>
                        <div className='row' style={{backgroundColor:'rgba(255,255,255,0.25)', paddingTop: 16, width:'100%', position: 'relative'}}>
                          <Button fullWidth={true} color="secondary" onClick={() => this.props.actions.login.openForgotDialog()}>Olvidé mi contraseña</Button>
                        </div>
                      </div>
                      : null
                    }
                    </div>
                </ReactCardFlip>
            </div>
            <div style={{height: 580, width: 400}}>
                <ReactCardFlip isFlipped={this.props.isSignUpFlipped} flipDirection="vertical">
                  <div style={{height: 540, width: 360, padding: 20, textAlign: 'center', fontSize: 32, color: '#122f55', borderTopRightRadius: 20, borderBottomRightRadius: 20, borderWidth: 1, borderColor: '#456389', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#456389', cursor: 'pointer'}} onClick={() => this.props.actions.user.setflipped('signUp', true)}>
                    Si aún no estás registrado en el sistema hacé click aquí para registrarte
                  </div>
                  <div style={{height: 580, width: 400, borderTopRightRadius: 20, borderBottomRightRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto'}}>
                    {
                      this.props.isSignUpFlipped ?
                      <Grid container style={{paddingLeft: 24, paddingRight: 24}}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant='outlined'
                            autoComplete='given-name'
                            margin='normal'
                            InputLabelProps={{ shrink: true }}
                            label='Nombre'
                            fullWidth={true}
                            required
                            value={this.props.user.userName}
                            onChange={(event) => this.props.actions.user.setField('userName', event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant='outlined'
                            autoComplete='family-name'
                            margin='normal'
                            InputLabelProps={{ shrink: true }}
                            label='Apellido'
                            fullWidth={true}
                            required
                            value={this.props.user.userLastName}
                            onChange={(event) => this.props.actions.user.setField('userLastName', event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant='outlined'
                            autoComplete='email'
                            margin='normal'
                            InputLabelProps={{ shrink: true, }}
                            label='Email'
                            fullWidth={true}
                            required
                            value={this.props.user.userEmail}
                            onChange={(event) => this.props.actions.user.setField('userEmail', event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant='outlined'
                            autoComplete='current-password'
                            type='password'
                            margin='normal'
                            InputLabelProps={{ shrink: true, }}
                            label='Contraseña'
                            fullWidth={true}
                            required
                            value={this.props.user.userPassword}
                            onChange={(event) => this.props.actions.user.setField('userPassword', event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant='outlined'
                            autoComplete='current-password'
                            type='password'
                            margin='normal'
                            InputLabelProps={{ shrink: true, }}
                            label='Repetir contraseña'
                            fullWidth={true}
                            required
                            value={this.props.user.userRepeatPassword}
                            onChange={(event) => this.props.actions.user.setField('userRepeatPassword', event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant='outlined'
                            autoComplete='tel'
                            margin='normal'
                            InputLabelProps={{ shrink: true, }}
                            label='Celular'
                            fullWidth={true}
                            required
                            value={this.props.user.userPhone}
                            onChange={(event) => this.props.actions.user.setField('userPhone', event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant='outlined'
                            autoComplete='id-number'
                            margin='normal'
                            InputLabelProps={{ shrink: true }}
                            label='DNI'
                            fullWidth={true}
                            required
                            value={this.props.user.userIdNumber}
                            onChange={(event) => this.props.actions.user.setField('userIdNumber', event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                            autoFocus
                            style={{marginTop: 16, marginBottom: 8}}
                          >
                            <InputLabel shrink style={{backgroundColor: 'white', paddingLeft: 2, paddingRight: 2}}>Provincia</InputLabel>
                            <Select
                              value={this.props.user.StateId}
                              onChange={(event) => this.props.actions.user.setSelectedState(event.target.value)}
                            >
                              {
                                _sortedStates && _sortedStates.map((_state) => {
                                  return (<MenuItem key={`userEditorState_${_state.id}`} value={_state.id}>{_state.stateName}</MenuItem>)
                                })
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant='outlined'
                            required
                            autoFocus
                            style={{marginTop: 16, marginBottom: 8}}
                          >
                            <InputLabel shrink style={{backgroundColor: 'white', paddingLeft: 2, paddingRight: 2}}>Ciudad</InputLabel>
                            <Select
                              value={this.props.user.CityId}
                              onChange={(event) => this.props.actions.user.setSelectedCity(event.target.value)}
                            >
                              {
                                _filteredCities.map((_city) => {
                                  return (<MenuItem key={`userEditorCity_${_city.id}`} value={_city.id}>{_city.cityName}</MenuItem>)
                                })
                              }
                              <MenuItem key={`userEditorCity_0`} value={0}>{'Otras'}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {
                          this.props.user.CityId===0?
                            <Grid item xs={12}>
                              <TextField
                                margin="normal"
                                variant='outlined'
                                required
                                InputLabelProps={{ shrink: true, }}
                                label='Ingrese el nombre de la ciudad'
                                fullWidth={true}
                                autoFocus={true}
                                value={this.props.user.userOtherCity}
                                onChange={(e) => this.props.actions.user.setField('userOtherCity', e.target.value)}
                              />
                            </Grid>
                          :
                            null
                        }
                        <Grid item xs={12}>
                          <TextField
                            margin="normal"
                            variant='outlined'
                            required
                            InputLabelProps={{ shrink: true }}
                            label='Dirección'
                            fullWidth={true}
                            autoFocus={true}
                            value={this.props.user.userAddress}
                            onChange={(e) => this.props.actions.user.setField('userAddress', e.target.value)}
                          />
                        </Grid>
                        <div className='row' style={{backgroundColor:'rgba(255,255,255,0.25)', paddingTop:16, width:'100%', position: 'relative'}}>
                          <Button variant="contained" fullWidth={true} color="secondary" onClick={() => this.props.actions.user.saveUser(this.props.history)}>Registrar</Button>
                        </div>
                      </Grid>
                      : null
                    }
                  </div>
                </ReactCardFlip>
            </div>
          </Grid>
        }
        <Dialog open={this.props.login.openForgotDialog} onClose={()=> this.props.actions.login.closeForgotDialog()} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Olvidé mi contraseña</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Por favor completa el email con el que registraste tu cuenta y te enviaremos una nueva clave temporal para que ingreses al sistema
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email"
              type="email"
              fullWidth
              value={this.props.login.email}
              onChange={(e) => this.setEmail(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> this.props.actions.login.closeForgotDialog()} color="primary">
              Cancelar
            </Button>
            <Button onClick={()=> this.props.actions.login.loginRecoverPassword()} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </BookingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.user.error,
    user: state.user.user,
    isSignInFlipped: state.user.signInFlipped,
    isSignUpFlipped: state.user.signUpFlipped,
    login: state.login,
    States: state.user.States,
    Cities: state.user.Cities
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(UserActions, dispatch),
      login: bindActionCreators(LoginActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignInUp));
