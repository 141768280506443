import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import VirtualizedTable from './virtualizedTable';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Container from './container';
import * as PetsListActions from '../actions/petsList';
import * as ContainerActions from '../actions/container';
import _ from 'underscore';

class AdminPetsPage extends Component {

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.actions.PetsList.updateHeight(height - 64);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    let customerId = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    this.props.actions.PetsList.getInitialData(customerId);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    let _customerId = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    let rows = this.props.pets.map((_pet) => {
      return ({
        petName: _pet.petName,
        petGender: _pet.petGender === 'M' ? 'Macho' : 'Hembra',
        specieName: _pet.Breed.Specie.specieName,
        breedName: _pet.Breed.breedName,
        userFullName: `${_pet.User.userName} ${_pet.User.userLastName}`,
        data: _pet
      });
    });

    rows = _.sortBy(rows, x => x.petName);

    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>{this.props.match.params && this.props.match.params.id ? 'Mascotas del usuario' : (this.props.roleName === 'USER' ? 'Tus mascotas' : 'Listado de mascotas')}</div>
          <VirtualizedTable
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
            onRowClick={(event) => this.props.actions.PetsList.selectPet(event.rowData.data,this.props.history)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Nombre', dataKey: 'petName' },
              { width: 120, flexGrow: 1.0, label: 'Raza', dataKey: 'breedName' },
              { width: 120, flexGrow: 1.0, label: 'Propietario', dataKey: 'userFullName' },
            ]}
          />
        </div>
        {
          this.props.match.params && this.props.match.params.id ?
            <Fab key='userClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
              <CloseIcon />
            </Fab>
          : null
        }
        {
          this.props.roleName === 'USER' || _customerId > 0 ?
            <Fab color='secondary' size='medium' style={{position: 'fixed', bottom: 60, right: 20}} onClick={() => this.props.actions.PetsList.createPet(this.props.history, this.props.roleName === 'USER' ? this.props.loginUserId : _customerId)}>
              <AddIcon />
            </Fab>
          : null
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.petsList.height,
    pets: state.petsList.pets,
    selectedPet: state.petsList.selectedPet,
    roleName: state.login.roleName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      PetsList: bindActionCreators(PetsListActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPetsPage);
