import {
  USER_RESET,
  USER_SET_NAME,
  USER_SET_LASTNAME,
  USER_SET_EMAIL,
  USER_SET_CELLPHONE,
  USER_SET_PASSWORD,
  USER_SET_REPEAT_PASSWORD,
  USER_SET_ID_NUMBER,
  USER_SET_STATUS,
  USER_SET_TERMS_ACCEPTANCE_DATE,
  USER_SET_ROLE_ID,
  USER_SET_ID,
  USER_SET_FLIPPED,
  USER_SET_STATES,
  USER_SET_CITIES,
  USER_UPDATE_STATE,
  USER_UPDATE_CITY,
  USER_SET_OTHER_CITY,
  USER_SET_ADDRESS
} from '../constants/actions';

const INITIAL_USER = {
  id: 0,
  userEmail: '',
  userName: '',
  userLastName: '',
  userPhone: '',
  userPassword: '',
  userRepeatPassword: '',
  userIdNumber: '',
  userStatus: 'A',
  userTermsAcceptanceDate: null,
  StateId: null,
  CityId: null,
  userOtherCity: '',
  userAddress: '',
  RoleId: 3,
}

const INITAL_STATE = {
  user: {...INITIAL_USER},
  signInFlipped: false,
  signUpFlipped: true,
  States: [],
  Cities: []
};

export default function overlay(state = {...INITAL_STATE}, action) {
  let _user = {...state.user};
  switch (action.type) {
  case USER_RESET:
    return {...INITAL_STATE};
  case USER_SET_ID:
    _user.id = action.value;
    return {...state, user: {..._user}};
  case USER_SET_FLIPPED:
    return {
      ...state,
      user: {...INITIAL_USER},
      signInFlipped: action.value.form === 'signIn' ? action.value.flipped : !action.value.flipped,
      signUpFlipped: action.value.form === 'signUp' ? action.value.flipped : !action.value.flipped
    };
  case USER_SET_NAME:
    _user.userName = action.value;
    return {...state, user: {..._user}};
  case USER_SET_LASTNAME:
    _user.userLastName = action.value;
    return {...state, user: {..._user}};
  case USER_SET_CELLPHONE:
    _user.userPhone = action.value;
    return {...state, user: {..._user}};
  case USER_SET_PASSWORD:
    _user.userPassword = action.value;
    return {...state, user: {..._user}};
  case USER_SET_REPEAT_PASSWORD:
    _user.userRepeatPassword = action.value;
    return {...state, user: {..._user}};
  case USER_SET_ID_NUMBER:
    _user.userIdNumber = action.value;
    return {...state, user: {..._user}};
  case USER_SET_OTHER_CITY:
    _user.userOtherCity = action.value;
    return {...state, user: {..._user}};
  case USER_SET_ADDRESS:
    _user.userAddress = action.value;
    return {...state, user: {..._user}};
  case USER_SET_EMAIL:
    _user.userEmail = action.value;
    return {...state, user: {..._user}};
  case USER_SET_STATUS:
    _user.userStatus = action.value;
    return {...state, user: {..._user}};
  case USER_SET_TERMS_ACCEPTANCE_DATE:
    _user.userTermsAcceptanceDate = action.value;
    return {...state, user: {..._user}};
  case USER_SET_ROLE_ID:
    _user.RoleId = action.value;
    return {...state, user: {..._user}};
  case USER_SET_STATES:
    return {...state, States: [...action.values]};
  case USER_SET_CITIES:
    return {...state, Cities: [...action.values]};
  case USER_UPDATE_STATE:
    _user.CityId = null;
    _user.StateId = action.value;
    return {...state, user: {..._user}};
  case USER_UPDATE_CITY:
    _user.CityId = action.value;
    return {...state, user: {..._user}};
  default:
    return state;
  }
}
