import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import * as SpeciesEditorActions from '../actions/speciesEditor';

class SpecieEditor extends Component {
  render() {
    let _title= (this.props.selectedSpecie?'Edición de especie ':' Alta de especie')
    + (this.props.selectedSpecie?this.props.selectedSpecie.specieName:'');
    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16}}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Nombre'
              fullWidth={true}
              autoFocus={true}
              value={this.props.newSpecie.specieName}
              onChange={(e) => this.props.actions.speciesEditor.updateField({attr:'specieName', value:e.target.value})}
            />
          </Grid>
        </Grid>
        <Fab key='specieClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
        <Fab key='specieSave' size="small" color="secondary" aria-label="Save" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.speciesEditor.saveSpecie(this.props.history)}>
          <SaveIcon />
        </Fab>
       {
         this.props.selectedSpecie ?
             <Fab key='specieDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'fixed', bottom: '60px', right: '20px'}} onClick={() => this.props.actions.speciesEditor.deleteSpecie(this.props.history)}>
               <DeleteIcon />
             </Fab>
          :
          null

       }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedSpecie: state.speciesList.selectedSpecie,
    newSpecie: state.speciesEditor,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      speciesEditor: bindActionCreators(SpeciesEditorActions, dispatch),
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(SpecieEditor);
