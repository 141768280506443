import {
  TERMS_RESET,
  TERMS_SET_LIST,
  TERMS_UPDATE_TERM_ACCEPTANCE
} from '../constants/actions';

const INITIAL_STATE = {
  terms: [],
};

export default function overlay(state = {...INITIAL_STATE}, action) {
  let _terms = [];
  let found = false;
  switch (action.type) {
  case TERMS_RESET:
    return {...INITIAL_STATE};
  case TERMS_SET_LIST:
    return {...state, terms: [...action.values]};
  case TERMS_UPDATE_TERM_ACCEPTANCE:
    _terms = [...state.terms];
    for (let i = 0; i < _terms.length && !found; i++) {
      if (_terms[i].id === action.value.id) {
        found = true;
        _terms[i].checked = action.value.checked;
      }
    }
    return {...state, terms: [..._terms]};
  default:
    return state;
  }
}
