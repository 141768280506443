import { combineReducers } from 'redux';
import login from './login';
import message from './message';
import overlay from './overlay';
import container from './container';
import user from './user';
import pet from './pet';
import lock from './lock';
import statesList from './statesList';
import statesEditor from './statesEditor';
import speciesList from './speciesList';
import speciesEditor from './speciesEditor';
import usersList from './usersList';
import petsList from './petsList';
import usersEditor from './usersEditor';
import terms from './terms';
import citiesList from './citiesList';
import citiesEditor from './citiesEditor';
import breedsList from './breedsList';
import breedsEditor from './breedsEditor';
import booking from './booking';
import petsEditor from './petsEditor';
import reports from './report';
import termsAndConditions from './termsAndConditions';

const rootReducer = combineReducers({
  booking,
  login,
  message,
  overlay,
  container,
  user,
  pet,
  statesList,
  statesEditor,
  speciesList,
  speciesEditor,
  usersList,
  petsList,
  usersEditor,
  terms,
  citiesList,
  citiesEditor,
  breedsList,
  breedsEditor,
  petsEditor,
  reports,
  lock,
  termsAndConditions
});

export default rootReducer;
