import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as TermsActions from '../actions/terms';
import Grid from '@material-ui/core/Grid';
import BookingContainer from './bookingContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Terms extends React.Component {
  componentDidMount() {
    this.props.actions.terms.getInitialData();
  }

  render() {
    return (
      <BookingContainer id="terms" activeStep={4} history={this.props.history} action={this.props.actions.terms.validateTerms} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Grid container spacing={0} style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{height: 500, width: 719, borderRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto', fontSize: 32, flexDirection: 'column', padding: 40, color:'#456389'}}>
            <div style={{justifyContent: 'flex-start', display: 'inline', textAlign: 'left'}}>
              {
                this.props.Terms.map((t) => {
                  return (
                    <div style={{display: "block"}}>
                      <FormControlLabel
                        control={<Checkbox icon={<Button variant="contained" color="primary" style={{width: 200}}>Aceptar</Button>} checkedIcon={<Button variant="contained" color="secondary" style={{width: 200}} startIcon={<CheckBoxIcon />} >Aceptado</Button>} name={`term_${t.id}`} onChange={(event) => this.props.actions.terms.updateTerms(t.id, event.target.checked)} />}
                        label={t.termsAndConditionsDescription}
                        labelPlacement="Top"
                        style={{fontSize: 16}}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Grid>
      </BookingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    Terms: state.terms.terms
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      terms: bindActionCreators(TermsActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Terms));
