import {
  LOCK_RESET,
  LOCK_SET_QUESTIONS,
  LOCK_SET_RULES,
  LOCK_UPDATE_QUESTION
} from '../constants/actions';

const INITIAL_STATE = {
  questions: [],
  rules: []
};

export default function overlay(state = {...INITIAL_STATE}, action) {
  let _questions = [];
  let found = false;
  switch (action.type) {
  case LOCK_RESET:
    return {...INITIAL_STATE};
  case LOCK_SET_QUESTIONS:
    return {...state, questions: [...action.values]};
  case LOCK_SET_RULES:
    return {...state, rules: [...action.values]};
  case LOCK_UPDATE_QUESTION:
    _questions = [...state.questions];
    for (let i = 0; i < _questions.length && !found; i++) {
      if (_questions[i].id === action.value.id) {
        found = true;
        _questions[i].checked = action.value.checked;
      }
    }
    return {...state, questions: [..._questions]};
  default:
    return state;
  }
}
