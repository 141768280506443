import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../actions/user';
import * as bookingActions from '../actions/booking';
import Grid from '@material-ui/core/Grid';
import BookingContainer from './bookingContainer';
import Calendar from 'react-calendar'
import '../resources/css/Calendar.css';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';


const styles = theme => ({});

class BookingSelector extends React.Component {

  componentDidMount() {
    this.props.actions.booking.getInitialData();
  }

  render() {
    return (
      <BookingContainer activeStep={0} history={this.props.history} action={this.props.actions.booking.validateSelection}>
        <Grid  container spacing={0} style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{height: 500, width: 719, borderRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto', fontSize: 32, flexDirection: 'row', padding: 40, color:'#456389'}}>
            <Grid item xs={12} sm={7} style={{display: 'flex', justifyContent: 'flex-start'}}>
              <Calendar minDetail="month" locale="es-ES" minDate={new Date()} calendarType="US" onClickDay={(value, event) => this.props.actions.booking.getDataByDate(value)} value={moment.unix(this.props.bookingDisplayDate).toDate()} />
            </Grid>
            <Grid item xs={12} sm={5} style={{display: 'flex', justifyContent: 'center'}}>
              <div className='bookingList'>
                {
                  this.props.bookingAvailableList.length === 0 ?
                    <div className='bookingListItem'>
                      <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', fontSize: 16}}>No hay turnos disponibles</div>
                    </div>
                  :
                    this.props.bookingAvailableList.map((item, index) => {
                      return (
                        <div key={`availableBooking_${index}`} className={this.props.bookingSelected && item.bookingStartDate === this.props.bookingSelected.bookingStartDate ? 'selectedBookingListItem' : 'bookingListItem'} onClick={() => this.props.actions.booking.selectBooking(item)}>
                          <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', fontSize: 16}}>{item.bookingStartTime}</div>
                        </div>
                      );
                    })
                }
              </div>
            </Grid>
          </div>
        </Grid>
      </BookingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookingDisplayDate: state.booking.bookingDisplayDate,
    bookingAvailableList: state.booking.bookingAvailableList,
    bookingSelected: state.booking.bookingSelected,
    error: state.user.error,
    user: state.user.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(userActions, dispatch),
      booking: bindActionCreators(bookingActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookingSelector));
