import * as types from '../constants/actions';
import fetch from 'isomorphic-fetch';
import async from 'async';
import config from '../resources/js/config.js';
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';

export function setField(field, value) {
  let _action = null;
  switch (field) {
    case 'id': _action = types.USER_SET_ID; break;
    case 'userName': _action = types.USER_SET_NAME; break;
    case 'userLastName': _action = types.USER_SET_LASTNAME; break;
    case 'userEmail': _action = types.USER_SET_EMAIL; break;
    case 'userPhone': _action = types.USER_SET_CELLPHONE; break;
    case 'userPassword': _action = types.USER_SET_PASSWORD; break;
    case 'userRepeatPassword': _action = types.USER_SET_REPEAT_PASSWORD; break;
    case 'userIdNumber': _action = types.USER_SET_ID_NUMBER; break;
    case 'userOtherCity': _action = types.USER_SET_OTHER_CITY; break;
    case 'userAddress': _action = types.USER_SET_ADDRESS; break;
    default:
      _action = null;
  }
  return {
    type: _action,
    value
  }
}

export function setSelectedState(value) {
  return {
    type: types.USER_UPDATE_STATE,
    value
  }
}

export function setSelectedCity(value) {
  return {
    type: types.USER_UPDATE_CITY,
    value
  }
}

export function setflipped(form, flipped) {
  return {
    type: types.USER_SET_FLIPPED,
    value: {form, flipped}
  }
}

export function saveUser(history, nextRoute = '') {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const validate = function (data, cb) {
      const _user = getState().user.user;
      const _emailPattern = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;

      let _error = '';
      if (_user.userName === null || _user.userName === '') _error = 'Tenes que ingresar tu nombre';
      else if (_user.userLastName === null || _user.userLastName === '') _error = 'Tenes que ingresar tu apellido';
      else if (_user.userEmail === null || _user.userEmail === '') _error = 'Tenes que ingresar una dirección de email';
      else if (!_emailPattern.test(_user.userEmail)) _error = 'Tenes que ingresar una dirección de email válida';
      else if (_user.userPassword === null || _user.userPassword === '' ) _error = 'Tenes que ingresar una clave';
      else if (_user.userRepeatPassword === null || _user.userRepeatPassword === '' ) _error = 'Tenes que confirmar la clave ingresada';
      else if (_user.userPassword !== _user.userRepeatPassword) _error = 'La clave no coincide con su confirmación';
      else if (_user.userPhone === null || _user.userPhone === '') _error = 'Tenes que ingresar tu número de celular';
      else if (_user.userIdNumber === null || _user.userIdNumber === '') _error = 'Tenes que ingresar tu DNI';
      else if (_user.StateId === null || _user.StateId === 0) _error = 'Tenes que seleccionar tu provincia';
      else if ((_user.CityId === null || _user.CityId === 0) && (_user.userOtherCity === null || _user.userOtherCity === '')) _error = 'Tenes que especificar tu ciudad';
      else if (_user.userAddress === null || _user.userAddress === '') _error = 'Tenes que ingresar tu dirección';
      return cb(_error !== '' ? new Error(_error) : null, null);
    };

    const save = function(data, cb) {
      const _user = getState().user.user;

      fetch(`${_uri}/api/users`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_user)
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(data => {
            dispatch(setField('id',data.id));
            cb(null, null);
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      validate,
      save
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          if (nextRoute !== '') history.push(nextRoute);
        }
    });
  }
}

export function getInitialData() {
  let _uri = config.server;

  return function (dispatch, getState) {
    const start = function(cb) {
      dispatch(OverlayActions.show());
      cb(null,null);
    };

    const end = function() {
      dispatch(OverlayActions.hide());
    };

    const getStates = function(data, cb) {
      fetch(_uri + '/api/states',
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer `+ localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(states => {
            cb(null,{ states });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    const getCities = function(data, cb) {
      fetch(_uri + '/api/cities',
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer `+ localStorage.getItem('token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }
      )
      .then(function(response) {
        if (!response.ok) {
          if (response.status < 500) {
            response.json()
            .then(data => {
              cb(new Error(data.message));
            })
          } else {
            cb(new Error(response.statusText));
          }
        } else {
          response.json()
          .then(cities => {
            cb(null,{ ...data, cities });
          })
        }
      })
      .catch(err => {
        cb(err);
      });
    };

    async.waterfall([
      start,
      getStates,
      getCities
    ], function (error,result) {
        end();
        if (error) {
          console.log(error);
          dispatch(MessageActions.show(error.message));
        } else {
          dispatch({type: types.USER_SET_STATES, values: result.states});
          dispatch({type: types.USER_SET_CITIES, values: result.cities});
        }
    });
  }
}
