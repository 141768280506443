//LOGIN
export const LOGIN_SET_EMAIL = 'LOGIN_SET_EMAIL';
export const LOGIN_SET_PWD = 'LOGIN_SET_PWD';
export const LOGIN_SET_PROFILE = 'LOGIN_SET_PROFILE';
export const LOGIN_CLEAN_DATA = 'LOGIN_CLEAN_DATA';
export const LOGIN_SET_DATA = 'LOGIN_SET_DATA';
export const LOGIN_SET_SELLERCODE ='LOGIN_SET_SELLERCODE';
export const LOGIN_OPEN_FORGOT_DIALOG = 'LOGIN_OPEN_FORGOT_DIALOG';
export const LOGIN_CLOSE_FORGOT_DIALOG = 'LOGIN_CLOSE_FORGOT_DIALOG';

//OVERLAY
export const OVERLAY_SHOW = 'OVERLAY_SHOW';
export const OVERLAY_HIDE = 'OVERLAY_HIDE';
export const OVERLAY_SET = 'OVERLAY_SET';

//MESSAGES
export const MESSAGE_SHOW = 'MESSAGE_SHOW';
export const MESSAGE_HIDE = 'MESSAGE_HIDE';

//CONTAINER
export const CONTAINER_TOGGLE_DRAWER = 'CONTAINER_TOGGLE_DRAWER';
export const CONTAINER_SET_CRITERIA = 'CONTAINER_SET_CRITERIA';

//HOME
export const HOME_SET_HEIGHT = 'HOME_SET_HEIGHT';

//STATES
export const STATES_LIST_SET_HEIGHT = 'STATES_LIST_SET_HEIGHT';
export const STATES_LIST_SET_LIST = 'STATES_LIST_SET_LIST';
export const STATES_LIST_SET_SELECTED_STATE = 'STATES_LIST_SET_SELECTED_STATE';
export const STATE_EDITOR_UPDATE_FIELD ='STATE_EDITOR_UPDATE_FIELD';

//SPECIES
export const SPECIES_LIST_SET_HEIGHT = 'SPECIES_LIST_SET_HEIGHT';
export const SPECIES_LIST_SET_LIST = 'SPECIES_LIST_SET_LIST';
export const SPECIES_LIST_SET_SELECTED_SPECIE = 'SPECIES_LIST_SET_SELECTED_SPECIE';
export const SPECIES_EDITOR_UPDATE_FIELD ='SPECIES_EDITOR_UPDATE_FIELD';

//CITIES
export const CITIES_LIST_SET_HEIGHT = 'CITIES_LIST_SET_HEIGHT';
export const CITIES_LIST_SET_LIST = 'CITIES_LIST_SET_LIST';
export const CITIES_LIST_SET_STATES = 'CITIES_LIST_SET_STATES';
export const CITIES_LIST_SET_SELECTED_CITY = 'CITIES_LIST_SET_SELECTED_CITY';
export const CITIES_EDITOR_UPDATE_FIELD ='CITIES_EDITOR_UPDATE_FIELD';
export const CITIES_LIST_SET_SELECTED_STATE = 'CITIES_LIST_SET_SELECTED_STATE';

//BREEDS
export const BREEDS_LIST_SET_HEIGHT = 'BREEDS_LIST_SET_HEIGHT';
export const BREEDS_LIST_SET_LIST = 'BREEDS_LIST_SET_LIST';
export const BREEDS_LIST_SET_SPECIES = 'BREEDS_LIST_SET_SPECIES';
export const BREEDS_LIST_SET_SELECTED_BREED = 'BREEDS_LIST_SET_SELECTED_BREED';
export const BREEDS_EDITOR_UPDATE_FIELD ='BREEDS_EDITOR_UPDATE_FIELD';
export const BREEDS_LIST_SET_SELECTED_SPECIE = 'BREEDS_LIST_SET_SELECTED_SPECIE';

//USERS
export const USERS_LIST_SET_HEIGHT = 'USERS_LIST_SET_HEIGHT';
export const USERS_LIST_SET_LIST = 'USERS_LIST_SET_LIST';
export const USERS_LIST_SET_SELECTED_USER = 'USERS_LIST_SET_SELECTED_USER';

//USER
export const USER_RESET = 'USER_RESET';
export const USER_SET_NAME = 'USER_SET_NAME';
export const USER_SET_LASTNAME = 'USER_SET_LASTNAME';
export const USER_SET_EMAIL = 'USER_SET_EMAIL';
export const USER_SET_CELLPHONE = 'USER_SET_CELLPHONE';
export const USER_SET_PASSWORD = 'USER_SET_PASSWORD';
export const USER_SET_REPEAT_PASSWORD = 'USER_SET_REPEAT_PASSWORD';
export const USER_SET_ID_NUMBER = 'USER_SET_ID_NUMBER';
export const USER_SET_STATUS = 'USER_SET_STATUS';
export const USER_SET_TERMS_ACCEPTANCE_DATE = 'USER_SET_TERMS_ACCEPTANCE_DATE';
export const USER_SET_ROLE_ID = 'USER_SET_ROLE_ID';
export const USER_SET_ID = 'USER_SET_ID';
export const USER_SET_FLIPPED = 'USER_SET_FLIPPED';
export const USER_SET_STATES = 'USER_SET_STATES';
export const USER_SET_CITIES = 'USER_SET_CITIES';
export const USER_UPDATE_STATE = 'USER_UPDATE_STATE';
export const USER_UPDATE_CITY = 'USER_UPDATE_CITY';
export const USER_SET_OTHER_CITY = 'USER_SET_OTHER_CITY';
export const USER_SET_ADDRESS = 'USER_SET_ADDRESS';

//PET
export const PET_RESET = 'PET_RESET';
export const PET_SET_ID = 'PET_SET_ID';
export const PET_SET_NAME = 'PET_SET_NAME';
export const PET_SET_BIRTHDATE = 'PET_SET_BIRTHDATE';
export const PET_SET_GENDER_ID = 'PET_SET_GENDER_ID';
export const PET_SET_SPECIE_ID = 'PET_SET_SPECIE_ID';
export const PET_SET_BREED_ID = 'PET_SET_BREED_ID';
export const PET_SET_SPECIES = 'PET_SET_SPECIES';
export const PET_SET_BREEDS = 'PET_SET_BREEDS';
export const PET_SET_PETS = 'PET_SET_PETS';
export const PET_SET_ERROR = 'PET_SET_ERROR';
export const PET_ADD = 'PET_ADD';
export const PET_REMOVE = 'PET_REMOVE';
export const PET_SET_USER_ID = 'PET_SET_USER_ID';
export const PET_SET_FLIPPED = 'PET_SET_FLIPPED';
export const PET_SET_SELECTED = 'PET_SET_SELECTED';

//PETS
export const PETS_LIST_SET_HEIGHT = 'PETS_LIST_SET_HEIGHT';
export const PETS_LIST_SET_LIST = 'PETS_LIST_SET_LIST';
export const PETS_LIST_SET_SELECTED_PET = 'PETS_LIST_SET_SELECTED_PET';

//TERMS
export const TERMS_SET_ACCEPTANCE = 'TERMS_SET_ACCEPTANCE';
export const TERMS_SET_ERROR = 'TERMS_SET_ERROR';

//PETS EDITOR
export const PETS_EDITOR_UPDATE_FIELD = 'PETS_EDITOR_UPDATE_FIELD';
export const PETS_EDITOR_RESET = 'PETS_EDITOR_RESET';
export const PETS_EDITOR_SET_SPECIES = 'PETS_EDITOR_SET_SPECIES';
export const PETS_EDITOR_SET_BREEDS = 'PETS_EDITOR_SET_BREEDS';
export const PETS_EDITOR_SET_HEALTHPLANS = 'PETS_EDITOR_SET_HEALTHPLANS';
export const PETS_EDITOR_UPDATE_SPECIE = 'PETS_EDITOR_UPDATE_SPECIE';
export const PETS_EDITOR_UPDATE_BREED = 'PETS_EDITOR_UPDATE_BREED';
export const PETS_EDITOR_UPDATE_PHOTO = 'PETS_EDITOR_UPDATE_PHOTO';

//USERS EDITOR
export const USERS_EDITOR_UPDATE_FIELD = 'PETS_EDITOR_UPDATE_FIELD';
export const USERS_EDITOR_RESET = 'PETS_EDITOR_RESET';
export const USERS_EDITOR_SET_STATES = 'USERS_EDITOR_SET_STATES';
export const USERS_EDITOR_SET_CITIES = 'USERS_EDITOR_SET_CITIES';
export const USERS_EDITOR_UPDATE_STATE = 'USERS_EDITOR_UPDATE_STATE';
export const USERS_EDITOR_UPDATE_CITY = 'USERS_EDITOR_UPDATE_CITY';
export const USERS_EDITOR_CHANGE_PASSWORD_DIALOG = 'USERS_EDITOR_CHANGE_PASSWORD_DIALOG';
export const USERS_EDITOR_CLEAR_PASSWORD_DIALOG = 'USERS_EDITOR_CLEAR_PASSWORD_DIALOG';

//BOOKING
export const BOOKING_RESET = 'BOOKING_RESET';
export const BOOKING_SET_ID = 'BOOKING_SET_ID';
export const BOOKING_SET_DATE = 'BOOKING_SET_DATE';
export const BOOKING_SET_TIME_RANGE = 'BOOKING_SET_TIME_RANGE';
export const BOOKING_SET_AVAILABLES_LIST = 'BOOKING_SET_AVAILABLES_LIST';
export const BOOKING_SET_SELECTED = 'BOOKING_SET_SELECTED';
export const BOOKING_SET_SAVE_RESULT = 'BOOKING_SET_SAVE_RESULT';

//REPORT
export const REPORT_SET_DATA = 'REPORT_SET_DATA';

//LOCK
export const LOCK_RESET = 'LOCK_RESET';
export const LOCK_SET_QUESTIONS = 'LOCK_SET_QUESTIONS';
export const LOCK_SET_RULES = 'LOCK_SET_RULES';
export const LOCK_UPDATE_QUESTION = 'LOCK_UPDATE_QUESTION';

// TERMSANDCONDITIONS
export const TERMSANDCONDITIONS_SET_DATA='TERMSANDCONDITIONS_SET_DATA';
export const TERMSANDCONDITIONS_EDITOR_UPDATE_FIELD = 'TERMSANDCONDITIONS_EDITOR_UPDATE_FIELD';
export const TERMS_RESET = 'TERMS_RESET';
export const TERMS_SET_LIST = 'TERMS_SET_LIST';
export const TERMS_UPDATE_TERM_ACCEPTANCE = 'TERMS_UPDATE_TERM_ACCEPTANCE';

// BOOKINGS LIST
export const BOOKINGS_LIST_SET_HEIGHT = 'BOOKINGS_LIST_SET_HEIGHT';
export const BOOKINGS_LIST_SET_LIST = 'BOOKINGS_LIST_SET_LIST';
export const BOOKINGS_LIST_SET_SELECTED_BOOKING = 'BOOKINGS_LIST_SET_SELECTED_BOOKING';
