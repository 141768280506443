import {
  BOOKING_RESET,
  BOOKING_SET_ID,
  BOOKING_SET_DATE,
  BOOKING_SET_TIME_RANGE,
  BOOKING_SET_AVAILABLES_LIST,
  BOOKING_SET_SELECTED,
  BOOKING_SET_SAVE_RESULT
} from '../constants/actions';
import moment from 'moment';

const INITAL_STATE = {
  id: 0,
  bookingDisplayDate: moment(moment().format('YYYY-MM-DD 00:00:00')).unix(),
  bookingDate: moment.utc(moment().format('YYYY-MM-DD 00:00:00')).unix(),
  bookingStartTime: null,
  bookingEndTime: null,
  LocationId: 1,
  ServiceId: 1,
  OperatorUserId: 1,
  BookerUserId: null,
  PetId: null,
  bookingStartDate: null,
  bookingEndDate: null,
  bookingComment: '',
  bookingStatus: 'A',
  bookingAvailableList: [],
  bookingSelected: null,
  bookingSaveResult: null
};

export default function overlay(state = {...INITAL_STATE}, action) {
  switch (action.type) {
  case BOOKING_RESET:
    return {...INITAL_STATE};
  case BOOKING_SET_ID:
    return {...state, id: action.value};
  case BOOKING_SET_DATE:
    const _bookingDisplayDate = moment(moment.unix(action.value).utc().format('YYYY-MM-DD HH:mm:ss') + moment().format('Z')).unix();
    return {...state, bookingDate: action.value, bookingDisplayDate: _bookingDisplayDate};
  case BOOKING_SET_TIME_RANGE:
    return {...state, bookingStartTime: action.start, bookingEndTime: action.end };
  case BOOKING_SET_AVAILABLES_LIST:
    return {...state, bookingAvailableList: action.values };
  case BOOKING_SET_SELECTED:
    return {...state, bookingSelected: action.value};
  case BOOKING_SET_SAVE_RESULT:
    return {...state, bookingSaveResult: action.value};
  default:
    return state;
  }
}
