import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import PawIcon from '@material-ui/icons/Pets';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PwdIcon from '@material-ui/icons/Https';

import * as UsersEditorActions from '../actions/usersEditor';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as MessageActions from '../actions/message';
import _ from "underscore";

class UserEditor extends Component {

  componentDidMount() {
    this.props.actions.usersEditor.getInitialData();
  }

  showUserChangePwdDialog(value){
    this.props.actions.usersEditor.showUserChangePwdDialog(value);
  }

  confirmChangePassword(){
    if(this.props.userOldPass.trim()==='')
      this.props.actions.message.show("Error al ingresar la clave actual");
    else if( this.props.userNewPass.trim()==='' || this.props.userNewPass!==this.props.userNewPassVerified )
      this.props.actions.message.show("Error al ingresar la nueva clave");
    else
      this.props.actions.usersEditor.changeUserPassword(this.props.history);
  }

  render() {
    let readOnly = (this.props.roleName === 'USER');
    let _title= (this.props.id > 0 ?'Edición de usuario':' Alta de usuario');

    const _sortedStates = _.sortBy(this.props.States, x => x.stateName);
    const _filteredCities = _.sortBy(this.props.Cities.filter(x => x.StateId === this.props.StateId), x => x.cityName);
    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16, marginBottom: 48}}>
          <Grid container xs={12}>
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Nombre'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userName}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userName', value:e.target.value})}
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Apellido'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userLastName}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userLastName', value:e.target.value})}
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='DNI'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userIdNumber}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userIdNumber', value:e.target.value})}
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Email'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userEmail}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userEmail', value:e.target.value})}
            />
            {this.props.id>0?
              null
              :
              <TextField
                margin="normal"
                InputLabelProps={{ shrink: true, }}
                label='Reingrese Email'
                fullWidth={true}
                autoFocus={true}
                value={this.props.userEmailVerified}
                onChange={(e) => this.props.actions.usersEditor.updateField({attr:'EmailVerified', value:e.target.value})}
              />
            }
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Teléfono'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userPhone}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userPhone', value:e.target.value})}
            />
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Dirección'
              fullWidth={true}
              autoFocus={true}
              value={this.props.userAddress}
              onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userAddress', value:e.target.value})}
            />
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 16}}
              disabled={readOnly}
            >
              <InputLabel shrink>Perfil</InputLabel>
              <Select
                value={this.props.RoleId}
                onChange={(event) => this.props.actions.usersEditor.updateField({attr:'RoleId', value:event.target.value})}
              >
                <MenuItem key={`userEditorRole_2`} value={2}>{'Administrador'}</MenuItem>
                <MenuItem key={`userEditorRole_3`} value={3}>{'Cliente'}</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 24}}
              disabled={readOnly}
            >
              <InputLabel shrink>Provincia</InputLabel>
              <Select
                value={this.props.StateId}
                onChange={(event) => this.props.actions.usersEditor.setSelectedState(event.target.value)}
              >
                {
                  _sortedStates && _sortedStates.map((_state) => {
                    return (<MenuItem key={`userEditorState_${_state.id}`} value={_state.id}>{_state.stateName}</MenuItem>)
                  })
                }

              </Select>
            </FormControl>
            <FormControl
              fullWidth
              required
              autoFocus
              style={{marginTop: 24}}
              disabled={readOnly}
            >
              <InputLabel shrink>Ciudad</InputLabel>
              <Select
                value={this.props.CityId}
                onChange={(event) => this.props.actions.usersEditor.setSelectedCity(event.target.value)}
              >
                {
                  _filteredCities.map((_city) => {
                    return (<MenuItem key={`userEditorCity_${_city.id}`} value={_city.id}>{_city.cityName}</MenuItem>)
                  })
                }
                <MenuItem key={`userEditorCity_0`} value={0}>{'Otras'}</MenuItem>
              </Select>
            </FormControl>
            {this.props.CityId===0?
              <TextField
                margin="normal"
                InputLabelProps={{ shrink: true, }}
                label='Ingrese el nombre de la ciudad'
                fullWidth={true}
                autoFocus={true}
                value={this.props.userOtherCity}
                onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userOtherCity', value:e.target.value})}
              />
            :
              null
            }


          </Grid>
        </Grid>
        <Fab key='userClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
        <Fab key='userPassword' size="small" color="secondary" aria-label="Pets" style={{display:(this.props.id>0?null:'none'),position: 'fixed', bottom: readOnly ? '100px' : '210px', right: '20px'}} onClick={() => this.showUserChangePwdDialog(true)}>
          <PwdIcon />
        </Fab>
        <Fab key='userPet' size="small" color="secondary" aria-label="Pets" style={{display:(this.props.id>0?null:'none'),position: 'fixed', bottom: readOnly ? '60px' : '160px', right: '20px'}} onClick={() => this.props.history.push(`/admin-pets/${this.props.id}`)}>
          <PawIcon />
        </Fab>

        {
          !readOnly ?
            <Fab key='userSave' size="small" color="secondary" aria-label="Save" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.usersEditor.saveUser(this.props.history)}>
              <SaveIcon />
            </Fab>
          : null
        }
        {
          !readOnly && this.props.id ?
            <Fab key='userDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'fixed', bottom: '60px', right: '20px'}} onClick={() => this.props.actions.usersEditor.deleteUser(this.props.history)}>
              <DeleteIcon />
            </Fab>
          : null
        }

        <Dialog open={this.props.userChangePwdDialog} onClose={()=>this.showUserChangePwdDialog(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cambio de clave</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para cambiar la clave por favor ingrese su clave actual y su nueva clave.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="userOldPassword"
            label="Clave Actual"
            type="password"
            fullWidth
            value={this.props.userOldPass}
            onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userOldPass', value:e.target.value})}
          />
          <TextField
            margin="dense"
            id="userNewPassword"
            label="Nueva Clave"
            type="password"
            fullWidth
            value={this.props.userNewPass}
            onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userNewPass', value:e.target.value})}
          />
          <TextField
            margin="dense"
            id="userNewPasswordVerified"
            label="Reingrese Nueva Clave"
            type="password"
            fullWidth
            value={this.props.userNewPassVerified}
            onChange={(e) => this.props.actions.usersEditor.updateField({attr:'userNewPassVerified', value:e.target.value})}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.showUserChangePwdDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={()=>this.confirmChangePassword()} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    id: state.usersEditor.user.id,
    userName: state.usersEditor.user.userName,
    userLastName: state.usersEditor.user.userLastName,
    userIdNumber: state.usersEditor.user.userIdNumber,
    userEmail: state.usersEditor.user.userEmail,
    userPhone: state.usersEditor.user.userPhone,
    userAddress: state.usersEditor.user.userAddress,
    RoleId: state.usersEditor.user.RoleId,
    roleName: state.login.roleName,
    userOldPass: state.usersEditor.user.userOldPass,
    userNewPass: state.usersEditor.user.userNewPass,
    userNewPassVerified:state.usersEditor.user.userNewPassVerified,
    userChangePwdDialog:state.usersEditor.userChangePwdDialog,
    StateId: state.usersEditor.user.StateId,
    CityId: state.usersEditor.user.CityId,
    States: state.usersEditor.States,
    Cities: state.usersEditor.Cities,
    userOtherCity:state.usersEditor.user.userOtherCity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      message: bindActionCreators(MessageActions, dispatch),
      usersEditor: bindActionCreators(UsersEditorActions, dispatch),
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(UserEditor);
