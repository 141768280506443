import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as LockActions from '../actions/lock';
import Grid from '@material-ui/core/Grid';
import BookingContainer from './bookingContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  card:{
    height:'10rem',
  }
});

class Locks extends React.Component {
  componentDidMount() {
    this.props.actions.locks.getInitialData();
  }

  render() {
    return (
      <BookingContainer id="signInUp" activeStep={3} history={this.props.history} action={this.props.actions.locks.validateLocks} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Grid container spacing={0} style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{height: 500, width: 719, borderRadius: 20, borderWidth: 1, borderColor: '#ddd', borderStyle: 'solid', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto', fontSize: 32, flexDirection: 'column', padding: 40, color:'#456389'}}>
            <div style={{justifyContent: 'flex-end', display: 'inline', textAlign: 'right'}}>
              {
                this.props.Questions.map((q) => {
                  return (
                    <div style={{display: "block"}}>
                      <FormControlLabel
                        control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} name={`question_${q.id}`} onChange={(event) => this.props.actions.locks.updateQuestion(q.id, event.target.checked)} />}
                        label={q.questionLabel}
                        labelPlacement="Start"
                        style={{fontSize: 32}}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Grid>
      </BookingContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    Questions: state.lock.questions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      locks: bindActionCreators(LockActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Locks));
