import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import VirtualizedTable from './virtualizedTable';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Container from './container';
import * as UsersListActions from '../actions/usersList';
import * as ContainerActions from '../actions/container';
import _ from 'underscore';

class AdminUsersPage extends Component {

  updateDimensions() {
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.actions.UsersList.updateHeight(height - 64);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
    this.props.actions.UsersList.getInitialData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    let rows = this.props.users.map((_user) => {
      return ({
        userName: _user.userName,
        userLastName: _user.userLastName,
        userIdNumber: _user.userIdNumber,
        data: _user
      });
    });

    rows = _.sortBy(rows, x => x.userLastName + ' ' + x.userName );

    return (
      <Container {...this.props}>
        <div style={{height: this.props.height}}>
          <div className='adminTitle'>Listado de Usuarios</div>
          <VirtualizedTable
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
            onRowClick={(event) => this.props.actions.UsersList.selectUser(event.rowData.data,this.props.history)}
            columns={[
              { width: 120, flexGrow: 1.0, label: 'Apellido', dataKey: 'userLastName' },
              { width: 120, flexGrow: 1.0, label: 'Nombre', dataKey: 'userName' },
              { width: 120, flexGrow: 1.0, label: 'DNI', dataKey: 'userIdNumber' }
            ]}
          />
        </div>
        <Fab color='secondary' size='medium' style={{position: 'fixed', bottom: 60, right: 20}}>
          <AddIcon onClick={() => this.props.actions.UsersList.selectUser(null,this.props.history)}/>
        </Fab>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    height: state.usersList.height,
    users: state.usersList.users,
    selectedUser: state.usersList.selectedUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      UsersList: bindActionCreators(UsersListActions, dispatch),
      Container: bindActionCreators(ContainerActions, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);
