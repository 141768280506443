import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Container from './container';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import * as BreedsEditorActions from '../actions/breedsEditor';

class BreedEditor extends Component {
  render() {
    let _title= (this.props.selectedBreed?'Edición de Raza ':' Alta de Raza')
    + (this.props.selectedBreed?this.props.selectedBreed.breedName:'');
    return (
      <Container {...this.props}>
        <div className='adminTitle'>{_title}</div>
        <Grid container spacing={16} style={{paddingLeft: 16, paddingRight: 16}}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              InputLabelProps={{ shrink: true, }}
              label='Nombre'
              fullWidth={true}
              autoFocus={true}
              value={this.props.newBreed.breedName}
              onChange={(e) => this.props.actions.breedsEditor.updateField({attr:'breedName', value:e.target.value})}
            />
          </Grid>
        </Grid>
        <Fab key='breedClose' size="small" color="secondary" aria-label="Discard" style={{position: 'fixed', top: '80px', right: '20px'}} onClick={() => this.props.history.goBack()}>
          <CloseIcon />
        </Fab>
        <Fab key='breedSave' size="small" color="secondary" aria-label="Save" style={{position: 'fixed', bottom: '110px', right: '20px'}} onClick={() => this.props.actions.breedsEditor.saveBreed(this.props.history)}>
          <SaveIcon />
        </Fab>
       {
         this.props.selectedBreed ?
             <Fab key='breedDelete' size="small" color="secondary" aria-label="Delete" style={{position: 'fixed', bottom: '60px', right: '20px'}} onClick={() => this.props.actions.breedsEditor.deleteBreed(this.props.history)}>
               <DeleteIcon />
             </Fab>
          :
          null

       }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedBreed: state.breedsList.selectedBreed,
    newBreed: state.breedsEditor,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      breedsEditor: bindActionCreators(BreedsEditorActions, dispatch),
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(BreedEditor);
