import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import * as MessageActions from '../actions/message';
import * as OverlayActions from '../actions/overlay';
import * as LoginActions from '../actions/login';
import logo from '../resources/img/logomma.png';

const styles = theme => ({
  pageLogin: {
    height: '100vh',
    width: '100vw',
    background: '#456389',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginBox: {
    width: '20vw',
    height: '30vw',
    minWidth: '200px',
    minHeight: '300px',
    margin: '10',
    padding: '10'
  },
  logo: {
    maxWidth: '100%',
    height: '5vw',
    width: '20vw' /* ie8 */
  }
});

class Login extends Component {

  setEmail(e) {
    this.props.actions.login.setEmail(e.target.value);
  }

  setPwd(e) {
    this.props.actions.login.setPwd(e.target.value);
  }

  loginGetData() {
    this.props.actions.login.loginGetData(this.props.history, this.props.location && this.props.location.state ? this.props.location.state.originalPath : '');
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.pageLogin}>
        <div className={classes.loginBox}>
          <img alt='logo' className={classes.logo} src={logo} style={{marginLeft: 16}} />
          <div className='text-center'>
            <div className='row' style={{paddingLeft:16, width:'100%', position: 'relative'}}>
              <TextField
                variant='outlined'
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                label='Email'

                fullWidth={true}
                value={this.props.login.email}
                onChange={(e) => this.setEmail(e)}
              />
            </div>
            <div className='row' style={{paddingLeft:16, width:'100%', position: 'relative'}}>
              <TextField
                variant='outlined'
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                label='Password'
                type='password'
                fullWidth={true}
                value={this.props.login.pwd}
                onChange={(e) => this.setPwd(e)}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    this.loginGetData()
                  }
                }}
                />
            </div>
            <div className='row' style={{padding:16, width:'100%', position: 'relative'}}>
              <Button variant="contained" fullWidth={true} color="primary" onClick={() => this.loginGetData()}>Entrar</Button>
            </div>
            <div className='row' style={{paddingLeft:16, paddingRight: 16, width:'100%', position: 'relative'}}>
              <Button fullWidth={true} color="secondary" onClick={() => this.props.actions.login.openForgotDialog()}>Olvidé mi contraseña</Button>
            </div>
          </div>
        </div>
        <Dialog open={this.props.login.openForgotDialog} onClose={()=> this.props.actions.login.closeForgotDialog()} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Olvidé mi contraseña</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Por favor completa el email con el que registraste tu cuenta y te enviaremos una nueva clave temporal para que ingreses al sistema
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email"
              type="email"
              fullWidth
              value={this.props.login.email}
              onChange={(e) => this.setEmail(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> this.props.actions.login.closeForgotDialog()} color="primary">
              Cancelar
            </Button>
            <Button onClick={()=> this.props.actions.login.loginRecoverPassword()} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.message,
    overlay: state.overlay,
    login: state.login
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      message: bindActionCreators(MessageActions, dispatch),
      overlay: bindActionCreators(OverlayActions, dispatch),
      login: bindActionCreators(LoginActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
